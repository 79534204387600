<template>
  <section class="apply6 mt-2">
    <h4 class="mb-2" style="text-align:left;">招生系別與名額維護:</h4>
    <header class="h1-dtc">
      <div v-for="(item, i) in titles" :key="i">{{item}}</div>
    </header>
  </section>
</template>

<script>
import queryString from "query-string";
const zero = "T00:00:00";
const titles = ["年度", "類型", "學校名稱", "科系", "籍屬", "名額", "名額總計"];
export default {
  name: "totalSpend6",
  data() {
    return {
      titles,
      quotas: [],
      countries: []
    };
  },

  methods: {
    async init() {
      try {
        const { Items: list } = window.axios.get(
          "countryidentity/CountryIdentityList"
        );
        this.countries = list;
        const { Items } = await window.axios.get(
          "schoolquota_headModels/GetAll"
        );
        const arr = [];
        Items.schoolquotas.forEach(s => {
          s.schoolquotas.forEach(t => {
            s.type = 1 ? (t.type = "學士") : (t.type = "碩士");
            t.year = s.BYear;
            arr.push(t);
          });
        });
        this.quotas = Items.schoolquotas;
      } catch (e) {}
    }
  },
  async mounted() {
    this.init();
  },
  beforeMount() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.apply6 {
  width: 1210px;
  display: block;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.h1-dtc {
  display: grid;
  grid-template-columns: 100px 100px 290px 400px 160px 80px 1fr;
  text-align: center;
  border: 1px solid black;
  > div {
    height: 30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    border-right: 1px solid black;
  }
  > div:last-child {
    border-right: none;
  }
}
</style>
